import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";

import { Loader } from "src/components";

const LoginCallback = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    async function handleLogin() {
      await oktaAuth.handleLoginRedirect();
    }

    handleLogin();
  }, [oktaAuth]);

  return <Loader />;
};

export default LoginCallback;
