import { useOktaAuth } from "@okta/okta-react";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { PATH_AUTH, PROFILE_PAGE } from "src/constants";
import { useCurrentAccount, useHasSeveralAccounts } from "src/hooks";
import { useAuthStore } from "src/stores/auth-store";

import { MainLayoutStructure } from "./_components";

type Props = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const { oktaAuth, authState } = useOktaAuth();

  const onLogout = useCallback(() => {
    logout();
    if (authState?.isAuthenticated) {
      oktaAuth.tokenManager.clear();
      oktaAuth.signOut();
    }
  }, [logout, oktaAuth, authState]);

  const currentAccount = useCurrentAccount();
  const hasSeveralAccounts = useHasSeveralAccounts();

  const toProfile = useCallback(() => {
    navigate(PROFILE_PAGE);
  }, [navigate]);
  const toAuth = useCallback(() => {
    navigate(PATH_AUTH);
  }, [navigate]);

  return (
    <MainLayoutStructure
      onLogout={onLogout}
      toProfile={toProfile}
      toAuth={toAuth}
      customer={currentAccount.customer}
      account={currentAccount}
      hasSeveralAccounts={hasSeveralAccounts}
    >
      {children}
    </MainLayoutStructure>
  );
};
